import styled from 'styled-components';
import { neutral20, sky60 } from '../../../assets/styles/colours';

export const BurgerIcon = styled.button`
  top: 24px;
  position: absolute;
  left: 24px;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: space-around;
  width: 25px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus-visible {
    border-radius: 1px;
    outline: 2px solid ${sky60};
    outline-offset: 2px;
  }

  div {
    width: 25px;
    height: 2px;
    background: ${neutral20};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ $open }) => ($open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ $open }) => ($open ? '0' : '1')};
      transform: ${({ $open }) =>
        $open ? 'translateX(20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ $open }) => ($open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;
