import './App.scss';
import { ThemeProvider } from 'styled-components';
import { HashRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Theme from './assets/styles/theme';
import { PortfolioRouter } from './router/PortfolioRouter';

const queryClient = new QueryClient();

function App() {
  return (
    <div className="root">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={Theme}>
          <Router>
            <PortfolioRouter />
          </Router>
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
