import { useEffect, useState } from 'react';

import { Theme } from '../assets/styles/theme';
import { debounce } from '../utils/debounce';

/**
 * Determines current window size range.
 *
 * A ranges doesn't include breakpoint and covers values below.
 *
 * @param {number} windowWidth Current window width.
 */
const getSize = (windowWidth) => ({
  isLowerThanXxs: windowWidth <= Theme.breakPointsNum.xxs,
  isLowerThanXs: windowWidth <= Theme.breakPointsNum.xs,
  isLowerThanSm: windowWidth <= Theme.breakPointsNum.sm,
  isLowerThanMd: windowWidth <= Theme.breakPointsNum.md,
  isLowerThanLg: windowWidth <= Theme.breakPointsNum.lg,
  isLowerThanXl: windowWidth <= Theme.breakPointsNum.xl,
});

export const useWindowSize = () => {
  const [view, setView] = useState(getSize(window.innerWidth));

  useEffect(() => {
    const handler = () => {
      setView(getSize(window.innerWidth));
    };
    const debouncedHandler = debounce(handler);

    window.addEventListener('resize', debouncedHandler);

    return () => window.removeEventListener('resize', debouncedHandler);
  }, []);

  return view;
};
