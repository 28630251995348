import styled from 'styled-components';
import { FontGlitch } from '../../shared/styles/FontGlith.style';

export const Title = styled.h2`
  font-family: 'Fira Mono', monospace;
  color: #fff;
  align-self: center;
  font-size: 2em;

  margin: 32px;

  ${FontGlitch}

  @media (max-width: ${({ theme }) => theme.breakPointsCss.md}) {
    font-size: 1.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakPointsCss.sm}) {
    font-size: 1.2rem;
  }
`;
