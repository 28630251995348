/**
 * Debounce function call.
 *
 * @param {function} func Called function.
 * @param {number} wait Debounce timer.
 * @param {boolean} [immediate] immediate call flag.
 */
function debounce(func, wait, immediate = false) {
  let timeout;

  return function () {
    let self = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(self, args);
      }
    };

    let callNow = immediate && !timeout;
    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
    if (callNow) func.apply(self, args);
  };
}

export { debounce };
