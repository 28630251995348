import styled from 'styled-components';

export const PageContainer = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  height: 100%;
  width: 100%;
  margin-top: 16px;
  padding: 16px;
  border-radius: 4px;
  overflow: auto;
  justify-content: space-between;
`;
