import styled from 'styled-components';
import { neutral20, sky30, sky60 } from '../../assets/styles/colours';

export const ScrollButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:focus-visible {
    outline: 2px solid ${sky60};
    outline-offset: 8px;
  }
`;

export const ScrollButton = styled.a`
  letter-spacing: 0.1em;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 45px;
  max-width: 100%;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;

  --uismLinkDisplay: var(--smLinkDisplay, inline-flex);
  display: var(--uismLinkDisplay);
  color: ${neutral20};
  outline: solid 1px ${neutral20};
  position: relative;
  transition-duration: 0.4s;
  overflow: hidden;

  &::before,
  span {
    margin: 0 auto;
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    transition-duration: 0.4s;
  }

  &:hover {
    outline: solid 1px ${sky30};
  }

  &:hover span {
    -webkit-transform: translateY(-400%) scale(-0.1, 20);
    transform: translateY(-400%) scale(-0.1, 20);
  }

  &::before {
    content: attr(data-sm-link-text);
    color: ${sky30};
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    -webkit-transform: translateY(500%) scale(-0.1, 20);
    transform: translateY(500%) scale(-0.1, 20);
  }

  &:hover::before {
    letter-spacing: 0.05em;
    -webkit-transform: translateY(0) scale(1, 1);
    transform: translateY(0) scale(1, 1);
  }

  &:hover {
    text-decoration: none;
  }
`;
