import styled from 'styled-components';
import { neutral20 } from '../../../../assets/styles/colours';
import { motion } from 'framer-motion/dist/framer-motion';
import { midnight100 } from '../../../../assets/styles/colours/midnight/midnight';

export const Tag = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${midnight100};
  border-radius: 5px;
  bottom: 15px;
  right: 15px;
  letter-spacing: 1px;
  padding: 3px;
  position: absolute;
  text-transform: uppercase;
  background-color: ${({ $color }) => $color};
`;

export const TimelineItemContent = styled.div`
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #292929;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  position: relative;
  width: 100%
  
  
  time {
    font-size: 12px;
    font-weight: bold;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 35px;
  }

  a {
    font-size: 14px;
    font-weight: bold;

    &::after {
      content: ' ►';
      font-size: 12px;
    }
  }
`;

export const TimelineItem = styled(motion.li)`
  display: block;
  color: ${neutral20};
  position: relative;
  margin: 10px 0;
  width: 100%;
  
  &:not(:first-child):after {
  content: "";
  background-color: #fff;
  position: absolute;
  width: 3px;
  height: 20px;
  top: -20px;
  left: 50%;
  display: block;
}
`;
