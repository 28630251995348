import React from 'react';

import * as SC from './NavBarIcon.style';
import { useNavigate } from 'react-router-dom';

export const NavBarIcon = ({ link, children, pageRanking, $visible }) => {
  const navigate = useNavigate();
  return (
    <SC.NavBarIcon
      $visible={$visible}
      onClick={() => navigate(link, { state: { pageRanking } })}
    >
      {children}
    </SC.NavBarIcon>
  );
};
