import React from 'react';

import * as SC from './ScrollButton.style';

export const ScrollButton = ({
  buttonText,
  hoverText,
  buttonClick = () => {},
}) => {
  return (
    <SC.ScrollButtonContainer onClick={buttonClick}>
      <SC.ScrollButton data-sm-link-text={hoverText}>
        <span>{buttonText}</span>
      </SC.ScrollButton>
    </SC.ScrollButtonContainer>
  );
};
