import { honey60, mojave70, sky60 } from '../../assets/styles/colours';

export const TIMELINE_DATA = [
  {
    text: 'Bought our first home.',
    date: 'April 2022',
    category: {
      tag: 'Personal',
      color: mojave70,
    },
  },
  {
    text: 'Paid the deposit on our first home.',
    date: 'January 2022',
    category: {
      tag: 'Personal',
      color: mojave70,
    },
  },
  {
    text: 'Promoted to Senior Front End Developer in WorkHuman',
    date: 'March 2021',
    category: {
      tag: 'Career',
      color: sky60,
    },
  },
  {
    text: 'Began AWS Certification',
    date: 'March 03 2017',
    category: {
      tag: 'Education',
      color: honey60,
    },
  },
  {
    text: 'Got our dog, Nala',
    date: 'March 2019',
    category: {
      tag: 'Personal',
      color: mojave70,
    },
  },
  {
    text: 'Transitioned role to Front End developer.',
    date: 'March 03 2017',
    category: {
      tag: 'Career',
      color: sky60,
    },
  },
  {
    text: 'Graduated College with a 1:1 honours Degree in Software Engineering',
    date: 'August 2018',
    category: {
      tag: 'Education',
      color: honey60,
    },
  },
  {
    text: 'Started Career in Ericsson as a Junior Java Developer.',
    date: 'January 2017',
    category: {
      tag: 'Career',
      color: sky60,
    },
  },
];
