import React from 'react';

import * as SC from './LandingIntro.style';

export const LandingIntro = () => {
  return (
    <SC.LandingIntro>
      <SC.LandingIntroHeading title="Hi,">Hi,</SC.LandingIntroHeading>
      <SC.LandingIntroHeading title="I'm Andrew McAleer.">
        I'm Andrew McAleer.
      </SC.LandingIntroHeading>
      <SC.LandingIntroSubHeading>
        Front End Developer.
      </SC.LandingIntroSubHeading>
    </SC.LandingIntro>
  );
};
