import styled from 'styled-components';

import { FontGlitch } from '../../../shared/styles/FontGlith.style';
import { neutral20 } from '../../../assets/styles/colours';

export const LandingIntro = styled.div`
  margin-left: 32px;
`;

export const LandingIntroHeading = styled.h1`
  color: ${neutral20};
  font-family: 'Fira Mono', monospace;
  font-size: 1.5em;

  @media (max-width: ${({ theme }) => theme.breakPointsCss.md}) {
    font-size: 1.3em;
  }

  @media (max-width: ${({ theme }) => theme.breakPointsCss.sm}) {
    font-size: 1.2em;
  }

  ${FontGlitch}
`;

export const LandingIntroSubHeading = styled.p`
  color: ${neutral20};
  letter-spacing: 3px;
`;
