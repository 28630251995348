import styled from 'styled-components';

export const Landing = styled.div`
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const GridContainer = styled.div`
  align-items: center;
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 0;
  grid-template-areas: 'landingImage landingIntro';

  @media (max-width: ${({ theme }) => theme.breakPointsCss.md}) {
    grid-template-areas: 'landingImage' 'landingIntro';
    grid-template-columns: 1fr;
  }
`;

export const LandingContact = styled.div`
  margin: 32px;
`;

export const LandingHTMLBody = styled.div`
  margin: 16px 0;
`;

export const LandingImage = styled.div`
  grid-area: landingImage;
  display: flex;
  justify-content: center;
`;

export const LandingIntro = styled.div`
  grid-area: landingIntro;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 1em;

  button {
    max-width: 200px;
  }
`;
