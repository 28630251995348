export const earth100 = '#00301F';
export const earth90 = '#023C28';
export const earth80 = '#177353';
export const earth70 = '#31A783';
export const earth60 = '#4FC4A1';
export const earth50 = '#71D4AB';
export const earth40 = '#8BDDB8';
export const earth30 = '#A4EBC7';
export const earth20 = '#C0F7D9';
export const earth10 = '#F0FFFA';
