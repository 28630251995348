export const sky100 = '#002D3B';
export const sky90 = '#003C4F';
export const sky80 = '#017093';
export const sky70 = '#0CA0D0';
export const sky60 = '#38D4FF';
export const sky50 = '#61E4FF';
export const sky40 = '#88ECFF';
export const sky30 = '#ADF4FF';
export const sky20 = '#D3F9FF';
export const sky10 = '#F0FBFF';
