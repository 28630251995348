import React from 'react';
import { useNavigate } from 'react-router-dom';

import { LandingIntro } from './LandingIntro/LandingIntro';
import { LandingImage } from './LandingImage/LandingImage';

import '../../shared/styles/flexGrid.scss';
import { HTMLSnippet } from '../../components/HTMLSnippet/HTMLSnippet';
import { ScrollButton } from '../../components/Button/ScrollButton';

import * as SC from './Landing.style';

export const Landing = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate('/contact');

  return (
    <>
      <SC.LandingHTMLBody>
        <HTMLSnippet text="<body>" />
      </SC.LandingHTMLBody>
      <SC.GridContainer>
        <SC.LandingImage>
          <LandingImage />
        </SC.LandingImage>
        <SC.LandingIntro>
          <LandingIntro />
          <SC.LandingContact>
            <ScrollButton
              buttonText="Interested?"
              hoverText="Contact Me."
              buttonClick={handleClick}
            />
          </SC.LandingContact>
        </SC.LandingIntro>
      </SC.GridContainer>
    </>
  );
};
