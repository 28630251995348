import React from 'react';

import * as SC from './HTMLSnippet.style';
import { useWindowSize } from '../../hooks/useWindowSize';

export const HTMLSnippet = ({ text }) => {
  const { isLowerThanMd } = useWindowSize();

  if (isLowerThanMd) {
    return null;
  }

  return <SC.HTMLSnippet>{text}</SC.HTMLSnippet>;
};
