import React from 'react';

import { TechnologyCard } from './TechnologyCard/TechnologyCard';
import { ReactComponent as ReactIcon } from '../../assets/icons/react.svg';
import { ReactComponent as JSIcon } from '../../assets/icons/javascript.svg';
import { ReactComponent as TSIcon } from '../../assets/icons/typescript.svg';
import { ReactComponent as StyledComponentsIcon } from '../../assets/icons/styled-components.svg';
import { ReactComponent as AWSIcon } from '../../assets/icons/aws.svg';
import { ReactComponent as HTML5 } from '../../assets/icons/html-5.svg';
import { ReactComponent as ES6 } from '../../assets/icons/es6.svg';
import { ReactComponent as I18n } from '../../assets/icons/i18n.svg';
import { ReactComponent as SASS } from '../../assets/icons/sass.svg';
import { ReactComponent as Jest } from '../../assets/icons/jest.svg';
import { ReactComponent as Redux } from '../../assets/icons/redux.svg';
import { ReactComponent as Linux } from '../../assets/icons/linux.svg';
import { ReactComponent as Git } from '../../assets/icons/git.svg';

import * as SC from './Technologies.style';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { HTMLSnippet } from '../../components/HTMLSnippet/HTMLSnippet';

const technologies = [
  {
    icon: <ReactIcon />,
    title: 'React',
  },
  {
    icon: <JSIcon />,
    title: 'JavaScript',
  },
  {
    icon: <TSIcon />,
    title: 'TypeScript',
  },
  {
    icon: <ES6 />,
    title: 'ES6',
  },
  {
    icon: <HTML5 />,
    title: 'HTML5',
  },
  {
    icon: <SASS />,
    title: 'SASS',
  },
  {
    icon: <Redux />,
    title: 'Redux',
  },
  {
    icon: <AWSIcon />,
    title: 'AWS',
  },
  {
    icon: <StyledComponentsIcon />,
    title: 'Styled-Components',
  },
  {
    icon: <I18n />,
    title: 'i18n',
  },
  {
    icon: <Jest />,
    title: 'Jest',
  },
  {
    icon: <Git />,
    title: 'Git',
  },
  {
    icon: <Linux />,
    title: 'Linux',
  },
];

export const Technologies = () => {
  return (
    <>
      <SC.HTMLSnippet>
        <HTMLSnippet text="<Article>" />
        <HTMLSnippet text="</Article>" />
      </SC.HTMLSnippet>
      <SC.Container>
        <SC.TitleContainer>
          <PageTitle title="Familiar Technologies" />
        </SC.TitleContainer>
        <SC.TechnologiesContainer>
          {technologies.map((technology) => (
            <TechnologyCard
              key={technology.title}
              icon={technology.icon}
              title={technology.title}
            >
              {technology.icon}
            </TechnologyCard>
          ))}
        </SC.TechnologiesContainer>
      </SC.Container>
    </>
  );
};
