import styled from 'styled-components';
import { PageTitle } from '../../../components/PageTitle/PageTitle';
import { FontGlitch } from '../../../shared/styles/FontGlith.style';
import { neutral20 } from '../../../assets/styles/colours';

export const Title = styled(PageTitle)`
  align-self: center;
  margin: 32px;

  ${FontGlitch}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

export const NoDataMessage = styled.h2`
  color: ${neutral20};
  text-align: center;
  flex-direction: row;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Fira Mono', monospace;
  opacity: 1;
  transition: opacity 500ms;
`;

export const TimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  &::after {
    background-color: ${neutral20};
    content: '';
    align-self: end;
    position: absolute;
    left: calc(50% - 2px);
    width: 4px;
    height: 100%;
  }
`;
