import React, { useState, Suspense } from 'react';
import Intro from '../../assets/videos/Intro.mp4';
import * as SC from './IntroVideo.style';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SpeakerMute } from '../../assets/icons/speaker-mute.svg';
import { ReactComponent as SpeakerUnmute } from '../../assets/icons/speaker-unmute.svg';
import { LoadingPage } from '../../pages/LoadingPage/LoadingPage';

export const IntroVideo = () => {
  const [isMuted, setIsMuted] = useState(true);
  const navigate = useNavigate();
  const updateMuted = () => setIsMuted((prevState) => !prevState);

  return (
    <Suspense fallback={<LoadingPage />}>
      <SC.MuteButton>
        {isMuted ? (
          <SpeakerMute onClick={() => updateMuted()} />
        ) : (
          <SpeakerUnmute onClick={() => updateMuted()} />
        )}
      </SC.MuteButton>

      <SC.IntroVideo>
        <video
          width="100%"
          height="100%"
          autoPlay
          muted={isMuted}
          onEnded={() => navigate('/landing')}
        >
          <source src={Intro} type="video/mp4" />
        </video>
      </SC.IntroVideo>
    </Suspense>
  );
};

IntroVideo.propTypes = {};
