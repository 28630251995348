import styled from 'styled-components';
import { mojave60, sky60 } from '../../../assets/styles/colours';
import { PageTitle as _PageTitle } from '../../PageTitle/PageTitle';

export const PageTitle = styled(_PageTitle)`
  margin: 32px;
`;

export const HTMLSnippet = styled.div`
  margin: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: space-between;
  flex-wrap: wrap;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 16px;
`;

export const FormContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContactForm = styled.form`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
`;

export const Input = styled.input`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 14px;

  &:focus-visible {
    outline: 2px solid ${sky60};
    outline-offset: 8px;
  }

  ${({ isError }) => isError && `border: 3px solid ${mojave60};`}
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 150px;
  background-color: #f8f8f8;
  resize: none;
  display: block;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 14px;
  font-family: Arial, serif;

  &:focus-visible {
    outline: 2px solid ${sky60};
    outline-offset: 8px;
  }

  ${({ isError }) => isError && `border: 3px solid ${mojave60};`}
`;

export const ErrorContainer = styled.div`
  height: 150px;
  padding-top: 8px;
`;

export const Error = styled.p`
  color: ${mojave60};
  text-align: center;
`;
