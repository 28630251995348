export const ocean100 = '#0D1A47';
export const ocean90 = '#0D2463';
export const ocean80 = '#0E3A96';
export const ocean70 = '#1253C2';
export const ocean60 = '#1D70E3';
export const ocean50 = '#619CFF';
export const ocean40 = '#94B8FF';
export const ocean30 = '#AFC6FD';
export const ocean20 = '#D1DCFE';
export const ocean10 = '#F0F5FF';
