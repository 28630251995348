import React from 'react';
import PropTypes from 'prop-types';
import * as SC from './AnimatedPage.style';

const defaultAnimation = {
  initial: { opacity: 0, y: 100 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -100 },
};

export const AnimatedPage = ({
  children,
  animations = defaultAnimation,
  display = 'flex',
}) => {
  return (
    <SC.AnimatedPage
      $display={display}
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.5 }}
    >
      {children}
    </SC.AnimatedPage>
  );
};

AnimatedPage.propTypes = {
  children: PropTypes.node,
};
