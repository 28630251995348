import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as SC from './TimelineFilter.style';
import { KEY_CODE } from '../../../constants/contstants';

export const TimelineFilter = ({ text = '', onClick, isChecked = true }) => {
  const handleKeyDown = (e) => {
    if (e.keyCode === KEY_CODE.ENTER) {
      e.target.click();
    }
  };

  const [checked, setChecked] = useState(isChecked);
  return (
    <SC.SwitchContainer>
      <SC.FlipSwitch
        id={text}
        name={text}
        checked={checked}
        $checked={checked}
        onKeyDown={handleKeyDown}
        onClick={() => {
          onClick();
          setChecked((prevState) => !prevState);
        }}
      />
      <SC.Label htmlFor={text}>{text}</SC.Label>
    </SC.SwitchContainer>
  );
};

TimelineFilter.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};
