import styled from 'styled-components';
import { sky60 } from '../../../assets/styles/colours';
import { motion } from 'framer-motion/dist/framer-motion';

export const ImageContainer = styled(motion.div)`
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  @media (max-width: ${({ theme }) => theme.breakPointsCss.md}) {
    width: 300px;
  }

  @media (max-width: ${({ theme }) => theme.breakPointsCss.sm}) {
    width: 400px;
  }
`;

export const LandingImage = styled.img`
  height: 500px;
  margin-left: 64px;
  filter: drop-shadow(0 2.5px 5px ${sky60});
  transition: filter 1.5s;

  &:hover {
    filter: drop-shadow(0 55px 150px ${sky60});
    transition: filter 1.5s;
  }

  @media (max-width: ${({ theme }) => theme.breakPointsCss.md}) {
    height: 500px;
  }

  @media (max-width: ${({ theme }) => theme.breakPointsCss.sm}) {
    height: 300px;
  }
`;
