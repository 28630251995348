import styled, { keyframes } from 'styled-components';
import { neutral20, sky60 } from '../../../assets/styles/colours';

const AnimateIconOn = keyframes`
  to {
     fill: #FCFCFC;
    filter: drop-shadow(20px 25px 50px  ${sky60});
    stroke-dashoffset: 0;
  }
`;

const AnimateIconOff = keyframes`
 0% {
    fill: #FCFCFC;
    filter: drop-shadow(20px 55px 150px ${sky60});
  }
  100%{
    fill: transparent;
    filter: none;
  }
`;

export const NavBarIcon = styled.button`
  visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
  transition: visibility 500ms;
  background: none;
  color: inherit;
  border: none;
  padding: 4px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 16px 8px;
  stroke-dasharray: 0;
  stroke-dashoffset: 0;

  &:hover {
    cursor: pointer;

    & path {
      stroke-dasharray: 200;
      stroke-dashoffset: 300;
      animation: ${AnimateIconOn} 1s linear forwards;
    }
  }

  &:focus-visible {
    border-radius: 1px;
    outline: 2px solid ${sky60};
    outline-offset: 2px;
  }

  & path {
    fill: transparent;
    stroke: ${neutral20};
    animation: ${AnimateIconOff} 1s linear forwards;
  }
`;
