import styled from 'styled-components';
import { midnight100 } from '../../../assets/styles/colours/midnight/midnight';
import {
  earth50,
  mojave60,
  neutral20,
  sky60,
} from '../../../assets/styles/colours';

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px;
`;

export const Label = styled.label`
  font-size: 20px;
  margin-left: 8px;
  color: white;
  
  @media (max-width: ${({ theme }) => theme.breakPointsCss.md}) {
    font-size: 1rem;
  }
`;

export const FlipSwitch = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  background: ${midnight100};
  display: flex;
  margin: 0;
  align-self: center;
  transition: background;
  width: 40px;
  height: 20px;
  -webkit-appearance: initial;
  border: 1px solid ${neutral20};
  border-radius: 12px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-weight: bold;
  cursor: pointer;
  
  @media (max-width: ${({ theme }) => theme.breakPointsCss.md}) {
      width: 30px;
      height: 15px;
  }

  &:after {
    position: absolute;
    top: 5%;
    display: block;
    width: 45%;
    height: 90%;
    background: ${({ $checked }) => (!$checked ? neutral20 : earth50)};
    box-sizing: border-box;
    text-align: baseline;
    transition: all 0.3s ease-in 0s;
    border-radius: 12px;
    left: 2%;
    content: '';

  }

  &:checked:after {
    left: 53%;
    background: ${mojave60}
    transition: background;
    content: '';
  }
  
  &:focus-visible {
    border-radius: 12px;
    outline: 2px solid ${sky60};
    outline-offset: 2px;
  }
`;
