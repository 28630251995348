export const humanity100 = '#10105C';
export const humanity90 = '#1F1A85';
export const humanity80 = '#2928AB';
export const humanity70 = '#3D40CC';
export const humanity60 = '#5B63E4';
export const humanity50 = '#878AF3';
export const humanity40 = '#A3A3F7';
export const humanity30 = '#C1C0FB';
export const humanity20 = '#E0DEFD';
export const humanity10 = '#F5F0FF';
