export const honey100 = '#452600';
export const honey90 = '#5F3A00';
export const honey80 = '#865900';
export const honey70 = '#C08C04';
export const honey60 = '#FFCF27';
export const honey50 = '#FFE860';
export const honey40 = '#FFEE86';
export const honey30 = '#FFF4AC';
export const honey20 = '#FFF9D3';
export const honey10 = '#FFFBF0';
