export const horizon100 = '#4C0C0C';
export const horizon90 = '#711313';
export const horizon80 = '#9C2222';
export const horizon70 = '#C13838';
export const horizon60 = '#EB5E5E';
export const horizon50 = '#EE8585';
export const horizon40 = '#F7A2A2';
export const horizon30 = '#F9BDBD';
export const horizon20 = '#FCDBDB';
export const horizon10 = '#FFF0F0';
