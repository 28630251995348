import React, { useEffect, useState } from 'react';
import { TIMELINE_DATA } from './TIMELINE_DATA';
import * as SC from './TimelineDesktop/TimelineDesktop.style';
import { TimelineFilter } from './TimelineFilter/TimelineFilter';
import { AnimatedText } from '../../components/AnimatedText/AnimatedText';
import { useWindowSize } from '../../hooks/useWindowSize';
import { TimelineDesktop } from './TimelineDesktop/TimelineDesktop';
import { TimelineMobile } from './TimelineMobile/TimelineMobile';

export const Timeline = () => {
  const placeholderText = [
    { type: 'heading1', text: "You don't wanna see my achievements? (ﾟ︵ﾟ)" },
  ];

  const [timelineData, setTimelineData] = useState(TIMELINE_DATA);
  const [selection, setSelection] = useState([]);

  const updateSelection = (option) =>
    selection.includes(option)
      ? setSelection(selection.filter((o) => o !== option))
      : setSelection((prevState) => [...prevState, option]);

  useEffect(() => {
    setTimelineData(
      TIMELINE_DATA.filter((item) => !selection.includes(item.category.tag))
    );
  }, [selection]);

  const { isLowerThanSm } = useWindowSize();

  const RenderedTimeline = !isLowerThanSm ? (
    <TimelineDesktop timelineData={timelineData} />
  ) : (
    <TimelineMobile timelineData={timelineData} />
  );

  return (
    <SC.Container>
      <SC.Title title="Achievements">Achievements</SC.Title>
      <TimelineFilter
        text="Personal"
        onClick={() => updateSelection('Personal')}
      />
      <TimelineFilter
        text="Education"
        onClick={() => updateSelection('Education')}
      />
      <TimelineFilter text="Career" onClick={() => updateSelection('Career')} />
      {RenderedTimeline}
      <SC.NoDataMessage>
        {placeholderText.map((item, index) => {
          return (
            <AnimatedText {...item} key={index} replay={!timelineData.length} />
          );
        })}
      </SC.NoDataMessage>
    </SC.Container>
  );
};
