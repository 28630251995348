export const slideDown = {
  initial: { opacity: 0, y: 1000 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -1000 },
};

export const slideUp = {
  initial: { opacity: 0, y: 1000 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -1000 },
};

export const fadeInOut = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};
