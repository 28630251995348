import React, { useState } from 'react';
import { NavBarIcon } from './NavBarIcon/NavBarIcon';

import { ReactComponent as Home } from '../../assets/icons/home.svg';
import { ReactComponent as Letter } from '../../assets/icons/letter.svg';
import { ReactComponent as Account } from '../../assets/icons/account.svg';
import { ReactComponent as Technologies } from '../../assets/icons/code.svg';

import * as SC from './NavBar.style';
import { PAGE_RANKINGS, PAGES } from '../../constants/contstants';
import { BurgerIcon } from './BurgerIcon/BurgerIcon';

const navItems = [
  { icon: <Home />, link: PAGES.landing, pageRanking: PAGE_RANKINGS.landing },
  {
    icon: <Technologies />,
    link: PAGES.technologies,
    pageRanking: PAGE_RANKINGS.technologies,
  },
  { icon: <Letter />, link: PAGES.contact, pageRanking: PAGE_RANKINGS.contact },
  {
    icon: <Account />,
    link: PAGES.experience,
    pageRanking: PAGE_RANKINGS.account,
  },
];

export const NavBar = () => {
  const sidebarCollapsed = localStorage.getItem('sidebar-collapsed');

  const [expanded, setExpanded] = useState(!sidebarCollapsed);

  const onHamburgerClick = () => {
    expanded
      ? localStorage.setItem('sidebar-collapsed', true)
      : localStorage.removeItem('sidebar-collapsed');

    setExpanded((prevState) => !prevState);
  };

  return (
    <SC.NavBarContainer>
      <BurgerIcon $open={expanded} onClick={onHamburgerClick} />
      <SC.NavBar $open={expanded}>
        {navItems.map((item) => (
          <NavBarIcon
            link={item.link}
            pageRanking={item.pageRanking}
            $visible={expanded}
            key={item.link}
          >
            {item.icon}
          </NavBarIcon>
        ))}
      </SC.NavBar>
    </SC.NavBarContainer>
  );
};
