import React from 'react';
import * as SC from './BurgerIcon.style';
import PropTypes from 'prop-types';

export const BurgerIcon = ({ $open = false, onClick }) => {
  return (
    <SC.BurgerIcon $open={$open} onClick={() => onClick()}>
      <div />
      <div />
      <div />
    </SC.BurgerIcon>
  );
};

BurgerIcon.propTypes = {
  $open: PropTypes.bool,
  onClick: PropTypes.func,
};
