export const coral100 = '#520D26';
export const coral90 = '#711335';
export const coral80 = '#9C224F';
export const coral70 = '#C1386A';
export const coral60 = '#EB5F92';
export const coral50 = '#EE86AC';
export const coral40 = '#F4A2C0';
export const coral30 = '#F9BFD4';
export const coral20 = '#FCDDE8';
export const coral10 = '#FFF0F5';
