import React from 'react';

import * as SC from './TechnologyCard.style';

export const TechnologyCard = ({ icon, title, children }) => {
  return (
    <SC.TechnologyCard tabIndex={1}>
      <SC.Icon> {children}</SC.Icon>
      <SC.Title>{title}</SC.Title>
    </SC.TechnologyCard>
  );
};
