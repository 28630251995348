export const midnight100 = '#1d1d1d';
export const midnight90 = '#343434';
export const midnight80 = '#a4a4a';
export const midnight70 = '#616161';
export const midnight60 = '#777777';
export const midnight50 = '#8e8e8e';
export const midnight40 = '#a5a5a5';
export const midnight30 = '#bbbbbb';
export const midnight20 = '#e8e8e8';
export const midnight10 = '#ffffff';
