import styled from 'styled-components';

export const IntroVideo = styled.div`
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  @media (min-aspect-ratio: 16/9) {
    width: 100%;
    height: auto;
  }

  @media (max-aspect-ratio: 16/9) {
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;

export const MuteButton = styled.div`
  z-index: 1000;
  position: absolute;
  top: 50px;
  right: 50px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  opacity: 50%;
`;
