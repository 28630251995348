import { css } from 'styled-components';

import * as colours from './colours';

const em12px = '0.75em';
const em14px = '0.875em';
const em16px = '1em';
const em20px = '1.25em';
const em24px = '1.5em';
const em26px = '1.625em';
const em32px = '2em';
const em40px = '2.5em';
const em48px = '3em';
const em60px = '3.75em';
const em64px = '4em';
const em80px = '5em';

const getTypographyCss = (fontSize, lineHeight) =>
  css`
    font-size: ${fontSize};
    line-height: ${lineHeight};
  `;

const neutralButtonVariant = {
  default: {
    background: colours.neutral20,
    hoverBackground: colours.neutral30,
    activeBackground: colours.neutral40,
    disabledBackground: colours.neutral20,
    text: colours.humanity80,
    disabledText: colours.humanity40,
  },

  // These shouldn't really exist...what are these variations of a grey button theme??
  // However, it seems they are needed for Assessments tests
  destructive: {
    background: 'transparent',
    hoverBackground: colours.horizon10,
    activeBackground: colours.horizon20,
    disabledBackground: 'transparent',
    text: colours.horizon70,
    disabledText: colours.horizon40,
  },
  success: {
    background: 'transparent',
    hoverBackground: colours.earth10,
    activeBackground: colours.earth20,
    disabledBackground: 'transparent',
    text: colours.earth70,
    disabledText: colours.earth40,
  },
};

export const Theme = {
  name: 'default',
  color: {
    ...colours,
    default: {
      primaryBackground: colours.humanity60,
      secondaryBackground: colours.neutral20,
      tertiaryBackground: colours.neutral10,
      primaryText: colours.neutral80,
      secondaryText: colours.neutral60,
      placeholderText: colours.neutral40,
      primaryBorder: colours.neutral50,
      secondaryBorder: colours.neutral30,
      tertiaryBorder: colours.humanity20,
      primaryHover: colours.ocean10,
      outlinedButtonHover: colours.humanity10,
      primaryError: colours.horizon70,
      primaryDisabled: colours.humanity20,
      primaryShadow: colours.neutral100,
      primaryOutline: colours.sky60,
      secondaryIconColor: colours.earth60,
      // TODO: should be possible to generate button styles based on default options - purple, red and green
      // Primary Button styles
      primaryButtonBackground: colours.humanity60,
      primaryButtonHoverBackground: colours.humanity70,
      primaryButtonActiveBackground: colours.humanity80,
      primaryButtonLoading: colours.humanity40,
      primaryButtonDisabled: colours.humanity40,
      primaryButtonText: 'white',
      // Secondary & Tertiary Button styles
      nonPrimaryButtonBackground: 'transparent',
      nonPrimaryButtonHoverBackground: colours.humanity10,
      nonPrimaryButtonActiveBackground: colours.humanity20,
      nonPrimaryButtonLoading: colours.humanity40,
      nonPrimaryButtonDisabled: colours.humanity40,
      nonPrimaryButtonText: colours.humanity70,

      black: 'black',
      white: 'white',
    },
    reflection: {
      dark: colours.sky80,
      light: colours.sky60,
    },
    checkin: {
      dark: colours.coral80,
      light: colours.coral60,
    },
    priority: {
      dark: colours.earth80,
      light: colours.earth60,
    },
    feedback: {
      dark: colours.ocean80,
      light: colours.ocean60,
    },
    button: {
      primary: {
        default: {
          background: colours.humanity60,
          hoverBackground: colours.humanity70,
          activeBackground: colours.humanity80,
          disabledBackground: colours.humanity40,
          text: 'white',
          disabledText: 'white',
        },
        destructive: {
          background: colours.horizon60,
          hoverBackground: colours.horizon70,
          activeBackground: colours.horizon80,
          disabledBackground: colours.horizon40,
          text: 'white',
          disabledText: 'white',
        },
        success: {
          background: colours.earth70,
          hoverBackground: colours.earth80,
          activeBackground: colours.earth90,
          disabledBackground: colours.earth40,
          text: 'white',
          disabledText: 'white',
        },
      },

      secondary: neutralButtonVariant,
      secondaryTerra: {
        default: {
          background: 'transparent',
          hoverBackground: colours.humanity10,
          activeBackground: colours.humanity20,
          disabledBackground: colours.humanity40,
          text: colours.humanity80,
          border: colours.humanity60,
          hoverBorder: colours.humanity70,
          focusBorder: colours.humanity70,
          activeBorder: colours.humanity60,
          disabledBorder: colours.humanity40,
          disabledText: colours.humanity40,
        },
        destructive: {
          background: 'transparent',
          hoverBackground: colours.horizon10,
          activeBackground: colours.horizon20,
          disabledBackground: colours.horizon40,
          text: colours.horizon70,
          border: colours.horizon60,
          hoverBorder: colours.horizon70,
          focusBorder: colours.horizon70,
          activeBorder: colours.horizon80,
          disabledBorder: colours.horizon40,
          disabledText: colours.horizon40,
        },
        success: {
          background: 'transparent',
          hoverBackground: colours.earth10,
          activeBackground: colours.earth20,
          disabledBackground: colours.earth40,
          text: colours.earth70,
          border: colours.earth60,
          hoverBorder: colours.earth50,
          focusBorder: colours.earth50,
          activeBorder: colours.earth50,
          disabledBorder: colours.earth40,
          disabledText: colours.earth40,
        },
      },
      tertiary: {
        default: {
          background: 'transparent',
          hoverBackground: colours.humanity10,
          activeBackground: colours.humanity20,
          disabledBackground: 'transparent',
          text: colours.humanity70,
          disabledText: colours.humanity40,
        },
        destructive: {
          background: 'transparent',
          hoverBackground: colours.horizon10,
          activeBackground: colours.horizon20,
          disabledBackground: 'transparent',
          text: colours.horizon70,
          disabledText: colours.horizon40,
        },
        success: {
          background: 'transparent',
          hoverBackground: colours.earth10,
          activeBackground: colours.earth20,
          disabledBackground: 'transparent',
          text: colours.earth70,
          disabledText: colours.earth40,
        },
      },

      // This isn't officially part of the Terra design, but is used for
      // the Close buttons within conversations entity pages
      neutral: neutralButtonVariant,
    },
  },
  space: {
    quarter: 2,
    half: 4,
    threeQuarter: 6,
    single: 8,
    onePointFive: 12,
    double: 16,
    twoPointFive: 20,
    triple: 24,
    quadruple: 32,
    fourPointFive: 36,
    quintuple: 40,
    sextuple: 48,
    septuple: 56,
    octuple: 64,
    nonuple: 72,
    decuple: 80,
    tenPointFive: 84,
    sexdecuple: 128,
    septendecuple: 136,
  },
  breakPointsNum: {
    xxs: 375,
    xs: 480,
    sm: 600,
    md: 720,
    lg: 1024,
    xl: 1440,
    xxl: 1920,
  },
  breakPointsCss: {
    xxs: '375px',
    xs: '480px',
    sm: '600px',
    md: '720px',
    lg: '1024px',
    xl: '1440px',
    xxl: '1920px',
  },
  fontSize: {
    em12px,
    em14px,
    em16px,
    em20px,
    em26px,
    em32px,
    em48px,
    em60px,
  },
  lineHeight: {
    em20px,
    em24px,
    em32px,
    em40px,
    em48px,
    em64px,
    em80px,
  },
  typography: {
    h1xl: getTypographyCss(em60px, `${80 / 60}em`),
    h1: getTypographyCss(em48px, `${64 / 48}em`),
    h2: getTypographyCss(em32px, `${48 / 32}em`),
    h3: getTypographyCss(em26px, `${40 / 26}em`),
    h4: getTypographyCss(em20px, `${32 / 20}em`),
    body1: getTypographyCss(em16px, `${24 / 16}em`), // default
    body2: getTypographyCss(em14px, `${24 / 14}em`),
    microcopy: getTypographyCss(em12px, `${20 / 12}em`),
  },
  // DO NOT USE! Use body1 instead (except at root of Terra)
  typographyRoot: getTypographyCss('16px', '24px'),
  decoration: {
    baseBorderRadius: '6px',
  },
};

export default Theme;
