import styled from 'styled-components';
import { neutral20 } from '../../assets/styles/colours';
import { BurgerIcon } from './BurgerIcon/BurgerIcon.style';

export const NavBar = styled.div`
  border-right: 1px solid ${neutral20};
  display: flex;
  flex-direction: column;
  transform: translateX(${({ $open }) => ($open ? 0 : '-63px')});

  ${BurgerIcon} {
    margin-top: 48px;
  }
  height: 100%;
  width: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0;
  transition: 0.8s all ease;
`;

export const NavBarContainer = styled.div`
  z-index: 1000;
`;
