import { Route, Routes, useLocation } from 'react-router-dom';
import Particle from 'react-tsparticles';
import styled from 'styled-components';
import { loadFull } from 'tsparticles';

import { NavBar } from '../components/NavBar/NavBar';
import { PageContainer } from '../components/Containers/PageContainer/PageContainer';
import { Landing } from '../pages/Landing/Landing';
import { Technologies } from '../pages/Technologies/Technologies';
import { Contact } from '../pages/Contact/Contact';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import { AnimatedPage } from '../components/AnimatedPage/AnimatedPage';
import { IntroVideo } from '../components/IntroVideo/IntroVideo';
import {
  fadeInOut,
  slideDown,
  slideUp,
} from '../assets/styles/animations/animations';
import { PAGE_RANKINGS, PAGES } from '../constants/contstants';
import { usePreviousValue } from '../hooks/usePreviousValue';
import { Timeline } from '../pages/Timeline/Timeline';
import { particlesConfig } from '../assets/config/particlesConfig.js';

const CanvasContainer = styled.div`
  z-index: -1;
`;

export const PortfolioRouter = () => {
  const location = useLocation();
  const splitUtl = location.pathname.split('/').filter((x) => x);
  const previousLocation = usePreviousValue(splitUtl);

  const customInit = async (engine) => {
    // this adds the bundle to tsParticles
    await loadFull(engine);
  };

  const pages = [
    {
      pageToRender: <Technologies />,
      path: PAGES.technologies,
    },
    { pageToRender: <Landing />, path: PAGES.landing },
    { pageToRender: <Contact />, path: PAGES.contact },
    { pageToRender: <Timeline />, path: PAGES.experience, display: 'block' },
    { pageToRender: <Landing />, path: '*' },
  ];

  const calculateAnimation = () => {
    if (PAGE_RANKINGS[splitUtl[0]] > PAGE_RANKINGS[previousLocation]) {
      return slideUp;
    }
    return slideDown;
  };

  return (
    <>
      {splitUtl.length && <NavBar width={100} height={100} />}
      <PageContainer>
        <CanvasContainer>
          <Particle options={particlesConfig} init={customInit} />
        </CanvasContainer>
        <AnimatePresence exitBeforeEnter initial={false}>
          <Routes location={location} key={location.pathname}>
            <Route
              path="/"
              element={
                <AnimatedPage animations={fadeInOut}>
                  <IntroVideo />
                </AnimatedPage>
              }
            />
            {pages.map(({ display, path, pageToRender, pageRanking }) => (
              <Route
                key={path}
                path={path}
                element={
                  <AnimatedPage
                    animations={calculateAnimation()}
                    display={display}
                  >
                    {pageToRender}
                  </AnimatedPage>
                }
              />
            ))}
          </Routes>
        </AnimatePresence>
      </PageContainer>
    </>
  );
};
