import React from 'react';
import PropTypes from 'prop-types';
import * as SC from './PageTitle.style';

export const PageTitle = ({ title }) => {
  return <SC.Title title={title}>{title}</SC.Title>;
};

PageTitle.propTypes = {
  title: PropTypes.string,
};
