import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient, useMutation } from 'react-query';

import { ScrollButton } from '../../Button/ScrollButton';
import { HTMLSnippet } from '../../HTMLSnippet/HTMLSnippet';
import { contactService } from '../../../services/Contact.service';
import * as SC from './ContactForm.style';

export const ContactForm = () => {
  const [sentSuccessful, setSentSuccessful] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: 'all',
  });

  const { invalidateQueries } = useQueryClient();

  const { mutate, isLoading } = useMutation(contactService, {
    onSuccess: () => {
      setSentSuccessful(true);
    },
    onError: (error) => {
      console.log(`error: ${error}`);
    },
    onSettled: () => {
      invalidateQueries('create');
    },
  });

  const onSubmit = (data) => {
    mutate({
      email: data.userEmail,
      subject: `Enquiry from ${data.username}`,
      message: data.userMessage,
    });
  };

  return (
    <>
      <SC.HTMLSnippet>
        <HTMLSnippet text="<Form>" />
        <HTMLSnippet text="</Form>" />
      </SC.HTMLSnippet>

      <SC.Container>
        <SC.PageTitle title="Contact" />
        <SC.FormContainer>
          {!sentSuccessful ? (
            <SC.ContactForm onSubmit={handleSubmit(onSubmit)}>
              <SC.Input
                {...register('username', {
                  required: 'Please include your name.',
                })}
                isError={errors.hasOwnProperty('username')}
                type="text"
                placeholder="Name"
                autoComplete="off"
              />
              <SC.Input
                {...register('userEmail', {
                  required: 'Please include your email.',
                })}
                isError={errors.hasOwnProperty('userEmail')}
                type="email"
                placeholder="Email"
                autoComplete="off"
              />
              <SC.TextArea
                {...register('userMessage', {
                  required: 'Please add a message.',
                  maxLength: {
                    value: 150,
                    message: 'Your message must not exceed 150 characters',
                  },
                  minLength: {
                    value: 5,
                    message: 'Your message must be more than 5 characters',
                  },
                })}
                isError={errors.hasOwnProperty('userMessage')}
                placeholder="Your Message"
                rows={4}
              />
              <ScrollButton
                buttonText="Finished?"
                hoverText="Send it."
                type="submit"
                dis
              />
              <SC.ErrorContainer>
                {Object.entries(errors).map((data) => (
                  <SC.Error key={data[1].type}>{data[1].message}</SC.Error>
                ))}
              </SC.ErrorContainer>
            </SC.ContactForm>
          ) : (
            <p>sent Success</p>
          )}
        </SC.FormContainer>
      </SC.Container>
    </>
  );
};
