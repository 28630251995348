import React from 'react';
import * as SC from './TimelineMobileItem.style';

export const TimelineMobileItem = ({
  color,
  tag,
  date,
  text,
  url,
  linkText,
}) => (
  <SC.TimelineItem>
    <SC.TimelineItemContent>
      <SC.Tag $color={color}>{tag}</SC.Tag>
      <time>{date}</time>
      <p>{text}</p>
      {url && linkText && (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {linkText}
        </a>
      )}
    </SC.TimelineItemContent>
  </SC.TimelineItem>
);
