import styled from 'styled-components';
import { neutral20, sky60 } from '../../assets/styles/colours';

export const HTMLSnippet = styled.p`
  font-family: 'Source Code Pro', monospace;
  color: ${neutral20};
  transition: color 1s;
  margin: 0;

  &:hover {
    color: ${sky60};
    transition: color 1s;
  }
`;
