import styled from 'styled-components';
import { sky60 } from '../../../assets/styles/colours';
import {
  midnight10,
  midnight100,
  midnight30,
  midnight90,
} from '../../../assets/styles/colours/midnight/midnight';

export const Title = styled.span`
  font-size: 2em;
  width: 100%;
  text-align: center;
  color: ${midnight10};
  transition: color 1000ms;

  @media (max-width: ${({ theme }) => theme.breakPointsCss.md}) {
    font-size: 1.5em;
  }

  @media (max-width: ${({ theme }) => theme.breakPointsCss.sm}) {
    font-size: 1em;
  }
`;

export const Icon = styled.div`
  margin: 24px;
`;

export const TechnologyCard = styled.div`
  width: 250px;
  height: 250px;
  border: 3px solid ${midnight90};
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${midnight100};
  transition: background-color 1000ms;

  &:focus-visible {
    border-radius: 1px;
    outline: 2px solid ${sky60};
    outline-offset: 2px;
  }

  svg {
    fill: ${midnight30};
    width: 100px;
    height: 100px;
    transition: fill 1000ms;
  }

  &:hover,
  &:focus-visible {
    cursor: pointer;
    background-color: ${midnight90};
    transition: background-color 500ms;

    svg {
      fill: ${sky60};
      transition: fill 500ms;
    }

    ${Title} {
      color: ${sky60};
      transition: color 500ms;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPointsCss.md}) {
    width: 200px;
    height: 200px;

    svg {
      width: 80px;
      height: 80px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakPointsCss.sm}) {
    width: 150px;
    height: 150px;

    svg {
      width: 40px;
      height: 40px;
    }
  }
`;
