import React from 'react';
import avatar from '../../../assets/images/avatar.png';

import * as SC from './LandingImage.style';

export const LandingImage = () => {
  return (
    <SC.ImageContainer
      drag
      dragTransition={{
        min: 0,
        max: 100,
        bounceDamping: 8,
      }}
    >
      <SC.LandingImage
        src={avatar}
        alt="Andrew McAleer Avatar"
        draggable={false}
      />
    </SC.ImageContainer>
  );
};
