import React from 'react';
import { Loader } from '../../components/Loader/Loader';
import * as SC from './LoadingPage.style';

export const LoadingPage = () => {
  return (
    <SC.LoadingPage>
      <Loader />
    </SC.LoadingPage>
  );
};
