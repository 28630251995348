export const mojave100 = '#4F170C';
export const mojave90 = '#792813';
export const mojave80 = '#AD451F';
export const mojave70 = '#E67135';
export const mojave60 = '#FF9C52';
export const mojave50 = '#FFC383';
export const mojave40 = '#FFD4A0';
export const mojave30 = '#FFE3BD';
export const mojave20 = '#FFF1DB';
export const mojave10 = '#FFF9F0';
