// body {
//   background-color: #212;
//   height: 100vh;
//   margin: 0;
//   display: grid;
//   place-items: center;
// }

import styled, { keyframes } from 'styled-components';
import { sky60 } from '../../assets/styles/colours';

export const loading = keyframes`
   25% {
    content: '|';
  }
  50% {
    content: '/';
  }
  75% {
    content: '—';
  }
  100% {
    content: '\\';
  }
`;

export const Loader = styled.div`
  color: ${sky60};
  font-family: monospace;
  font-size: 3rem;
  font-weignt: bold;

  &::before {
    content: '';
    animation: ${loading} 0.6s infinite;
  }
`;
