import React from 'react';
import PropTypes from 'prop-types';
import * as SC from './TimelineDesktop.style';
import { TimelineDesktopItem } from './TimelineDektopItem/TimelineDesktopItem';
import {
  AnimatePresence,
  AnimateSharedLayout,
  motion,
} from 'framer-motion/dist/framer-motion';

export const TimelineDesktop = ({ timelineData }) => {
  return (
    <AnimateSharedLayout>
      <motion.ul layout>
        <AnimatePresence>
          {timelineData.length > 0 && (
            <SC.TimelineContainer>
              {timelineData.map((data, idx) => (
                <TimelineDesktopItem
                  text={data?.text}
                  date={data?.date}
                  linkText={data?.link?.text}
                  url={data?.link?.url}
                  tag={data?.category?.tag}
                  color={data?.category?.color}
                  key={idx}
                />
              ))}
            </SC.TimelineContainer>
          )}
        </AnimatePresence>
      </motion.ul>
    </AnimateSharedLayout>
  );
};

TimelineDesktop.propTypes = { timelineData: PropTypes.array };
