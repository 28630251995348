const landing = 'landing';
const contact = 'contact';
const technologies = 'technologies';
const account = 'account';
const experience = 'experience';

export const PAGES = {
  landing,
  contact,
  technologies,
  account,
  experience,
};

export const PAGE_RANKINGS = {
  landing: 1,
  technologies: 2,
  contact: 3,
  account: 4,
};

export const KEY_CODE = {
  ESC: 27,
  ENTER: 13,
  SPACE: 32,
  TAB: 9,
};
