export const neutral100 = '#0D173D';
export const neutral90 = '#18275C';
export const neutral80 = '#283B7B';
export const neutral70 = '#3D5298';
export const neutral60 = '#576DB0';
export const neutral50 = '#788CC5';
export const neutral40 = '#A7B8E5';
export const neutral30 = '#BECDF2';
export const neutral20 = '#EBF1FF';
export const neutral10 = '#F7FAFF';
