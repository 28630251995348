import React from 'react';
import PropTypes from 'prop-types';
import * as SC from './TimelineMobile.style';

import {
  AnimatePresence,
  AnimateSharedLayout,
  motion,
} from 'framer-motion/dist/framer-motion';
import { TimelineMobileItem } from './TimelineMobileItem/TimelineMobileItem';

export const TimelineMobile = ({ timelineData }) => {
  return (
    <AnimateSharedLayout>
      <motion.ul layout>
        <AnimatePresence>
          {timelineData.length > 0 && (
            <SC.TimelineContainer>
              {timelineData.map((data, idx) => (
                <TimelineMobileItem
                  text={data?.text}
                  date={data?.date}
                  linkText={data?.link?.text}
                  url={data?.link?.url}
                  tag={data?.category?.tag}
                  color={data?.category?.color}
                  key={idx}
                />
              ))}
            </SC.TimelineContainer>
          )}
        </AnimatePresence>
      </motion.ul>
    </AnimateSharedLayout>
  );
};

TimelineMobile.propTypes = { timelineData: PropTypes.array };
